import { useEffect } from 'react';

import { DocumentId } from '../../types';
import useDebounce from '../../utils/useDebounce';
import {
  updateReadingPosition,
  updateScrollPosition,
} from '../stateUpdaters/persistentStateUpdaters/documents/progressRelated';

// This hook is used to update the scroll position of a video document based on the current video progress.
export function useUpdateVideoScrollPosition({
  docId,
  videoDuration,
  playedSeconds,
}: { docId?: DocumentId; videoDuration?: number; playedSeconds?: number }) {
  const debouncedPlayedSeconds = useDebounce(playedSeconds ?? 0, 5000, 6000);

  useEffect(() => {
    if (!debouncedPlayedSeconds || !videoDuration || !docId) {
      return;
    }

    // Sometimes played seconds is bigger than video duration, so we need to clamp it to 1.
    const newScrollDepth = Math.min(debouncedPlayedSeconds / videoDuration, 1);

    updateScrollPosition(
      docId,
      { scrollDepth: newScrollDepth, serializedPosition: null },
      {
        eventName: 'document-scroll-position-updated',
        userInteraction: 'scroll',
        isUndoable: false,
      },
    );

    updateReadingPosition(
      docId,
      { scrollDepth: newScrollDepth, serializedPosition: null },
      {
        eventName: 'document-progress-position-updated',
        userInteraction: 'scroll',
        isUndoable: false,
      },
    );
  }, [docId, debouncedPlayedSeconds, videoDuration]);
}
